import { Transform } from 'class-transformer'

export default function DateTransform() {
  const toPlain = Transform((data) => {
    if (data.value instanceof Date) return data.value.getTime()
    return data.value
  }, {
    toPlainOnly: true,
  })

  const toClass = Transform((data) => {
      if (data.value instanceof Date) return data.value
      else if (Number.isInteger(data.value)) return new Date(data.value)
      return null
    },
    {
      toClassOnly: true,
    })

  // eslint-disable-next-line
  return function (target: any, key: string) {
    toPlain(target, key)
    toClass(target, key)
  }
}
