import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/IndexView.vue')
  },
  {
    path: '/:id',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/:id/start/:table?',
    name: 'TableSelector',
    component: () => import('@/views/TableSelectorView.vue')
  },
  {
    path: '/:id/menu/:menuId/:sectionId?',
    name: 'Menu',
    component: () => import('@/views/MenuView.vue')
  },
  {
    path: '/:id/fixed-menu/:fixedMenuId/:selectionId?',
    name: 'FixedMenu',
    component: () => import('@/views/FixedMenuView.vue')
  },
  {
    path: '/:id/dish/:dishId/:selectionId?',
    name: 'Dish',
    component: () => import('@/views/DishView.vue')
  },
  {
    path: '/:id/order',
    name: 'Order',
    component: () => import('@/views/OrderView.vue')
  },
  {
    path: '/:id/payment',
    name: 'Payment',
    component: () => import('@/views/PaymentView.vue')
  },
  {
    path: '/:id/payment/online',
    name: 'PaymentOnline',
    component: () => import('@/views/PaymentOnlineView.vue')
  },
  {
    path: '/:id/payment/success',
    name: 'PaymentSuccess',
    component: () => import('@/views/PaymentSuccessView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
