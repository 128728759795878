import { plainToClass, Type } from 'class-transformer'
import { Observer, ObserverEvents } from '../Observer'
import { Server } from '../Server'
import { DeliveryInfo } from './DeliveryInfo'
import { HeyCall } from './HeyCall'
import { OrderDishSelection, OrderDishSelectionType } from './OrderDishSelection'
import { OrderFixedMenuSelection, OrderFixedMenuSelectionType } from './OrderFixedMenuSelection'
import { OrderSelection } from './OrderSelection'
import { PayCall } from './PayCall'
import { Price } from './Price'
import { Table } from './Table'

export class Order {
  @Type(() => Boolean) public online = false
  @Type(() => Boolean) public payFirst = false
  @Type(() => Boolean) public readOnly = false
  @Type(() => Table) public table: Table = new Table()
  @Type(() => String) public hash: string | null = null
  @Type(() => String) public number: string | null = null
  @Type(() => String) public code: string | null = null
  @Type(() => HeyCall) public heyCall: HeyCall | null = null
  @Type(() => PayCall) public payCall: PayCall | null = null
  @Type(() => DeliveryInfo) public delivery: DeliveryInfo = new DeliveryInfo()
  @Type(() => Price) public deliveryFee: Price | null = null
  @Type(() => Price) public tip: Price | null = null

  private static _instance: Order

  private _lines: Array<OrderDishSelection | OrderFixedMenuSelection> = []

  public static get Instance(): Order {
    return this._instance || (this._instance = new this())
  }

  public static set Instance(order: Order) {
    this._instance = order
    // notify about this
    Observer.Instance.publish(ObserverEvents.OrderChanged, order)
  }

  public get lines(): Array<OrderDishSelection | OrderFixedMenuSelection> {
    return this._lines
  }

  public set lines(lines: Array<OrderDishSelection | OrderFixedMenuSelection>) {
    this._lines = []
    // assign lines
    for (let index = 0; index < lines.length; index++) {
      const aLine = lines[index]
      if (aLine instanceof OrderDishSelection || aLine instanceof OrderFixedMenuSelection) {
        this._lines.push(aLine)
      } else {
        if ((aLine as Record<string, unknown>).type === OrderDishSelectionType) {
          this._lines.push(plainToClass(OrderDishSelection, aLine as OrderDishSelection))
        } else if ((aLine as Record<string, unknown>).type === OrderFixedMenuSelectionType) {
          this._lines.push(plainToClass(OrderFixedMenuSelection, aLine as OrderFixedMenuSelection))
        }
      }
    }
  }

  public isInitiated(): boolean {
    return this.table.isComplete() || this.online
  }

  public isCancelable(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed) {
        return false
      }
    }
    return true
  }

  public async addLine(line: OrderDishSelection | OrderFixedMenuSelection): Promise<void> {
    this.replaceOrAddLine(await Server.Instance.addOrderLine(line))
  }

  public async updateLine(line: OrderDishSelection | OrderFixedMenuSelection): Promise<void> {
    this.replaceOrAddLine(await Server.Instance.updateOrderLine(line))
  }

  public async setOrderLineQuantity(line: OrderDishSelection | OrderFixedMenuSelection, quantity: number): Promise<void> {
    this.replaceOrAddLine(await Server.Instance.setOrderLineQuantity(line, quantity))
  }

  public totalPrice(includeDeliveryFee: boolean): Price {
    const price = new Price()
    // calcule the total amount
    for (let index = 0; index < this.lines.length; index++) {
      price.value += this.lines[index].totalPrice().value
    }
    // apply also the delivery fee
    if (this.deliveryFee && includeDeliveryFee) {
      price.value += this.deliveryFee.value
    }
    // the price
    return price
  }

  public totalUnpaidPrice(): Price {
    const price = new Price()
    // calcule the total amount
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].paid)
        price.value += this.lines[index].totalPrice().value
    }
    // the price
    return price
  }

  public totalPaidPrice(includeDeliveryFee: boolean): Price {
    const price = new Price()
    // calcule the total amount
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].paid)
        price.value += this.lines[index].totalPrice().value
    }
    // apply also the delivery fee
    if (this.deliveryFee && includeDeliveryFee) {
      price.value += this.deliveryFee.value
    }
    // the price
    return price
  }

  private replaceOrAddLine(line: OrderDishSelection | OrderFixedMenuSelection): void {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].id === line.id) {
        if (line.quantity > 0) {
          this.lines[index] = line
        } else {
          this.lines.splice(index, 1)
        }
        this.linesChanged()
        return
      }
    }
    // is a new line
    this.lines.push(line)
    this.linesChanged()
  }

  private linesChanged(): void {
    Observer.Instance.publish(ObserverEvents.OrderLinesChanged, this)
  }

  public hasNotConfirmedLines(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].confirmed) {
        return true
      }
    }
    return false
  }

  public notConfirmedLines(): OrderSelection[] {
    const lines: OrderSelection[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].confirmed) {
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public hasPaidLines(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].paid) {
        return true
      }
    }
    return false
  }

  public hasUnpaidLines(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].confirmed && !this.lines[index].paid) {
        return true
      }
    }
    return false
  }

  public paidLines(): OrderSelection[] {
    const lines: OrderSelection[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (this.lines[index].paid) {
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public unpaidLines(includeNotConfirmed: boolean): OrderSelection[] {
    const lines: OrderSelection[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if (!this.lines[index].paid && (includeNotConfirmed || this.lines[index].confirmed)) {
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public hasUnservedLinesAndUnpaidLines(includeNotConfirmed: boolean): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      if ((!this.lines[index].paid || !this.lines[index].served) && (includeNotConfirmed || this.lines[index].confirmed)) {
        return true
      }
    }
    return false
  }

  public unservedLinesAndUnpaidLines(includeNotConfirmed: boolean): OrderSelection[] {
    const lines: OrderSelection[] = []
    for (let index = 0; index < this.lines.length; index++) {
      if ((!this.lines[index].paid || !this.lines[index].served) && (includeNotConfirmed || this.lines[index].confirmed)) {
        lines.push(this.lines[index])
      }
    }
    return lines
  }

  public secondsSinceLastWaiterCall(): number | null {
    if (this.heyCall) {
      const now = new Date().getTime()
      return (now - this.heyCall.date.getTime()) / 1000
    }
    return null
  }

  public secondsSinceLastWaiterCallForPay(): number | null {
    if (this.payCall) {
      const now = new Date().getTime()
      return (now - this.payCall.date.getTime()) / 1000
    }
    return null
  }

  public hasDishesWhichRequiresKitchen(): boolean {
    for (let index = 0; index < this.lines.length; index++) {
      const line = this.lines[index]
      if (line instanceof OrderDishSelection) {
        if (line.dish.kitchen) return true
      } else { // line instanceof OrderFixedMenuSelection
        for (let index2 = 0; index2 < line.selection.length; index2++) {
          if (line.selection[index2].dish.kitchen) return true
        }
      }
    }
    return false
  }
}