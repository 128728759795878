<template>
  <div
    class="bottom-bar-container-box animate__animated"
    :class="barAnimation"
  >
    <div class="bottom-bar container-box-max-width">
      <div
        class="item ml-3"
        @click="goHome()"
      >
        <i
          class="fal fa-utensils-alt"
          style="float: left"
        ></i>
        <span class="is-size-5 ml-2">Inicio</span>
      </div>
      <template v-if="order.readOnly">
        <div
          class="item mr-3 is-right-aligned"
          @click="viewOrder()"
        >
          <button class="button is-black">
            <i class="fal fa-shopping-cart mr-3"></i> Mi pedido
          </button>
        </div>
      </template>
      <template v-else>
        <template v-if="order.hasUnservedLinesAndUnpaidLines(true) && (order.online || order.payFirst)">
          <div class="item is-center-aligned">
            <button
              class="button is-success is-small"
              @click="payOnlineOrder()"
            >
              PAGAR
            </button>
          </div>
        </template>
        <template v-else>
          <div
            v-if="order.hasNotConfirmedLines()"
            class="item is-center-aligned"
          >
            <button
              class="button is-black is-small"
              @click="confirmOrder()"
            >
              CONFIRMAR
            </button>
          </div>
          <div
            v-else-if="order.hasUnpaidLines()"
            class="item is-center-aligned animate__animated"
            :class="{'animate__bounceIn': isOrderView, 'animate__bounceOut': !isOrderView}"
          >
            <button
              class="button is-success is-small"
              @click="payOrder()"
            >
              FINALIZAR
            </button>
          </div>
        </template>
        <div
          v-if="order.isInitiated()"
          class="item mr-3 is-right-aligned animate__animated"
          :class="priceAnimation"
          @click="viewOrder()"
        >
          <span class="is-size-5 mr-2">{{ totalPrice.toString() }}</span>
          <i
            class="fal fa-shopping-cart"
            style="float: right"
          ></i>
        </div>
        <div
          v-else-if="hasActiveChannels"
          class="item mr-3 is-right-aligned"
        >
          <button
            v-if="isOnlineOnly"
            class="button is-black"
            :class="{'is-loading': loading}"
            :disabled="loading"
            @click="startOnlineOrder()"
          >
            INICIAR PEDIDO
          </button>
          <button
            v-else
            class="button is-black"
            @click="startOrder()"
          >
            SELECCIONAR MESA
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">

import { dialog } from '@/components/ModalConfirmation.vue'
import { Order } from '@/models/Entities/Order'
import { Price } from '@/models/Entities/Price'
import { Restaurant } from '@/models/Entities/Restaurant'
import { ONLINE_TABLE_ID } from '@/models/Entities/Tables'
import { Observer, ObserverEvents } from '@/models/Observer'
import { Server } from '@/models/Server'
import router from '@/router'
import { Vue } from 'vue-class-component'

export default class BottomBar extends Vue {

  private totalPrice = new Price()
  private order = Order.Instance
  private restaurant: Restaurant | null = null

  private loading = false

  private barAnimation = ''
  private priceAnimation = ''

  private lastOrderCallAcceptance: number | null = JSON.parse(localStorage.getItem('lastOrderCallAcceptance') as string) as number

  public get hasActiveChannels(): boolean {
    return this.restaurant?.hasActiveChannels() ?? false
  }

  public get isOnlineOnly(): boolean {
    return this.restaurant?.salesChannels.takeawayOrDeliveryOnly() ?? false
  }

  private get isOrderView(): boolean {
    return this.$router.currentRoute.value.name === 'Order'
  }

  public mounted(): void {

    Observer.Instance.subscribe(ObserverEvents.RestaurantDidLoad, () => {
      this.restaurant = Restaurant.current()
    })

    Observer.Instance.subscribe(ObserverEvents.OrderChanged, (order: Order) => {
      this.order = order
      this.updatePrice(false)
      this.displayPaymentCallAccepted()
    })

    Observer.Instance.subscribe(ObserverEvents.OrderLinesChanged, (order: Order) => {
      this.order = order
      this.updatePrice(true)
    })

    Observer.Instance.subscribe(ObserverEvents.ShowBottomBar, (animated = true) => {
      this.show(animated)
    })

    Observer.Instance.subscribe(ObserverEvents.HideBottomBar, (animated = true) => {
      this.hide(animated)
    })
  }

  private goHome(): void {
    router.push({ name: 'Home' })
  }

  private viewOrder(): void {
    router.push({ name: 'Order' })
  }

  private startOrder(): void {
    this.$router.push({ name: 'TableSelector' })
  }

  private async startOnlineOrder(): Promise<void> {
    this.loading = true
    // prepare the order to initialize
    const order = new Order()
    order.table.tablesId = ONLINE_TABLE_ID
    // send to server
    Order.Instance = await Server.Instance.initOrder(this.restaurant as Restaurant, order, '') as Order
    // reload page
    this.loading = false
  }

  private show(animated: boolean): void {
    if (animated) this.barAnimation = 'animate__fadeInUp animate__faster'
    else this.barAnimation = 'visible'
  }

  private hide(animated: boolean): void {
    if (animated) this.barAnimation = 'animate__fadeOutDown animate__faster'
    else this.barAnimation = 'hidden'
  }

  private updatePrice(animated: boolean): void {
    if (animated) {
      this.priceAnimation = 'animate__rubberBand animate__fast'
      setTimeout(() => this.priceAnimation = '', 750)
    }
    // display the new total price
    this.totalPrice = this.order.totalUnpaidPrice()
  }

  private displayPaymentCallAccepted(): void {
    if (this.order.payCall && this.order.payCall.ok && this.lastOrderCallAcceptance !== this.order.payCall.date.getTime()) {
      // store it
      const store = this.order.payCall.date.getTime()
      localStorage.setItem('lastOrderCallAcceptance', JSON.stringify(store))
      this.lastOrderCallAcceptance = store
      // display message
      dialog().ask('Tu llamada ha sido atendida', 'Un miembro de nuestro personal está de camino para cobrarte.', ['¡Perfecto!'])
    }
  }

  private async confirmOrder(): Promise<void> {
    let message = '<p>Lo que tengas seleccionado no se podrá modificar ni cancelar.</p>'
    // should display the kitchen full message?
    if ((this.restaurant as Restaurant).liveOptions?.kitchenFull && this.order.hasDishesWhichRequiresKitchen()) {
      message += '<p class="mt-3 has-text-danger"><b>Actualmente la cocina está muy ocupada, y puede que tu pedido pueda demorarse un poco más de lo habitual.</b></p>'
    }
    // display dialog
    if (await dialog().ask('¿Seguro que quieres confirmar el pedido?', message, ['Confirmar', 'No'])) {
      Order.Instance = await Server.Instance.confirmOrder()
    }
  }

  private async payOrder(): Promise<void> {
    let next = this.order.online || this.order.payFirst
    if (!next) {
      next = await dialog().ask('¿Has terminado la comida?',
        'Si ya has terminado de comer y no vas a seguir pidiendo, puedes pagar ahora, pero si aún no has terminado puedes pagar más tarde.',
        ['Si, pagar ahora', 'No, pagar más tarde']) as boolean
    }
    if (next) await this.$router.push({ name: 'Payment' })
  }

  private payOnlineOrder(): void {
    this.$router.push({ name: 'PaymentOnline' })
  }
}
</script>

<style
  lang="scss"
  scoped
>
// .bottom-bar {}
// this style is defined in "style.scss" due to some needed global vars

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}
</style>