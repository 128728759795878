<template>
  <div
    class="modal"
    :class="{ 'is-active': visible }"
  >
    <div
      class="modal-background animate__animated"
      :class="backgroundAnimation"
    ></div>
    <div
      class="modal-content has-text-centered animate__animated"
      :class="cardAnimation"
    >
      <p
        class="is-size-5 mt-4 mb-2 has-text-weight-semibold"
        v-html="title"
      ></p>
      <p
        class="is-size-6 mb-4 has-text-grey"
        v-html="message"
      ></p>
      <hr />
      <div class="mt-5 is-centered buttons">
        <button
          v-for="(option, index) in options"
          :key="option"
          class="button"
          :class="{ 'is-dark': index === 0 }"
          @click="optionSelected(index)"
        >
          {{ option }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { Vue } from 'vue-class-component'

export default class ModalConfirmation extends Vue {
  private visible = false
  private title = ''
  private message = ''
  private options: string[] = []
  private resolve!: CallableFunction

  private hideTimeout = 0
  private backgroundAnimation = ''
  private cardAnimation = ''

  private static _instance: ModalConfirmation

  public static get Instance(): ModalConfirmation {
    return ModalConfirmation._instance
  }

  public created(): void {
    ModalConfirmation._instance = this
  }

  public ask(title: string, message: string, options: string[]): Promise<boolean | number> {
    // cancel the previous hide time-out
    if (this.hideTimeout > 0) {
      clearTimeout(this.hideTimeout)
    }
    // display the dialog
    this.visible = true
    this.title = title
    this.message = message
    this.options = options
    // display animated
    this.backgroundAnimation = 'animate__fadeIn'
    this.cardAnimation = 'animate__fadeInUpSmall animate__faster'
    // publish this fact
//    Observer.Instance.publish(ObserverEvents.ModalDialogActivated)
    // the response
    return new Promise<boolean | number>(resolve => {
      this.resolve = resolve
    })
  }

  public optionSelected(option: number): void {
    this.resolve(this.options.length > 2 ? option : option === 0)
    this.close()
  }

  public close(): void {
    // hide view
    this.backgroundAnimation = 'animate__fadeOut'
    this.cardAnimation = 'animate__fadeOutDownSmall animate__faster'
    // pubish this fact
//    Observer.Instance.publish(ObserverEvents.ModalDialogDesactivated)
    this.hideTimeout = setTimeout(() => {
      this.visible = false
    }, 850)
  }
}

export function dialog(): ModalConfirmation {
  return ModalConfirmation.Instance
}
</script>

<style
  lang="scss"
  scoped
>
</style>