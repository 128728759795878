import { Type } from 'class-transformer'
import { Entity } from '@/models/Entities/Entity'
import { Price } from '@/models/Entities/Price'
import { Dish } from '@/models/Entities/Dish'

export class DishesGroup extends Entity {
    @Type(() => Price) public price: Price | null = null
    @Type(() => Dish) public dishes: Dish[] = []
    public title!: string
    public description!: string
    public minSelection = 1
    public maxSelection = 1

    public dishesIds(): string[] {
        const dishes: string[] = []
        this.dishes.forEach((dish: Dish) => {
            dishes.push(dish.id)
        })
        return dishes
    }

    public isOptional(): boolean {
        return this.price !== null
    }
}