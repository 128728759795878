import DateTransform from '@/models/DateTransform'

export class WaiterCall {

    public ok!: boolean

    @DateTransform() public date!: Date
/*
    private _date!: Date

    public get date(): Date {
        return this._date
    }

    public set date(value: Date) {
        if (value instanceof Date) this._date = value
        else this._date = new Date(value * 1000)
    }
 */
}
