import { Allergen } from '@/models/Entities/Allergen'
import { Category } from '@/models/Entities/Category'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { Entity } from '@/models/Entities/Entity'
import { Photo } from '@/models/Entities/Photo'
import { Price } from '@/models/Entities/Price'
import { Server } from '@/models/Server'
import { Type } from 'class-transformer'

export class Dish extends Entity {
  @Type(() => Price) public price!: Price
  @Type(() => Allergen) public allergens!: Allergen[]
  @Type(() => Category) public category!: Category
  @Type(() => Photo) public photo!: Photo
  @Type(() => String) title!: string
  @Type(() => String) description!: string
  @Type(() => Boolean) kitchen!: boolean
  @Type(() => DishesGroup) public dishesGroups!: DishesGroup[]

  public constructor() {
    super()
    this.price = new Price()
    this.allergens = []
  }

  public photoUrl(placeholder: boolean): string | null {
    if (this.hasPhotoUrl()) {
      return Server.Instance.resourceUrl('photo/dish/' + this.id + '/512x512?nc=' + this.photo.id)
    }
    return placeholder ? require('@/assets/images/common/dish-no-photo.svg') : null
  }

  public hasPhotoUrl(): boolean {
    return !!this.photo
  }

  public hasComplements(): boolean {
    return this.dishesGroups && this.dishesGroups.length > 0
  }
}