<template>
  <section class="section hero is-fullheight is-error-section">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <div class="has-text-centered">
              <div class="error-page-icon">
                <span class="icon"><i
                  class="mdi"
                  :class="icon"
                ></i></span>
              </div>
              <div class="error-page-caption">
                <h1>{{ title }}</h1>
                <slot name="description" />
              </div>
              <div class="error-page-action">
                <div class="buttons is-centered">
                  <slot name="buttons" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>
</template>

<script lang="ts">

import { Observer, ObserverEvents } from '@/models/Observer'
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class ErrorDialog extends Vue {
  @Prop({ default: true }) private fullscreen!: boolean
  @Prop({ default: '' }) private title!: string
  @Prop({ default: '' }) private icon!: string

  private to: string | null = null

  public beforeMount(): void {
    if (this.fullscreen) {
      Observer.Instance.publish(ObserverEvents.FullscreenEnabled)
    }
  }

  public beforeUnmount(): void {
    if (this.fullscreen) {
      Observer.Instance.publish(ObserverEvents.FullscreenDisabled)
    }
  }
}
</script>