import { Restaurant } from '@/models/Entities/Restaurant'
import { Observer, ObserverEvents } from '@/models/Observer'
import { Order } from './Entities/Order'
import { Server } from './Server'

export class Crontab {
  private static _instance: Crontab

  private id = 0
  private lastLiveOptionsHash = ''

  public static get Instance(): Crontab {
    return this._instance || (this._instance = new this())
  }

  public start(): void {
    if (this.id === 0) {
      this.updateOrder()
    }
  }

  public pause(): void {
    clearInterval(this.id)
    this.id = 0
  }

  public async update(): Promise<void> {
    if (Order.Instance.isInitiated()) {
      const result = await Server.Instance.activeOrder(Order.Instance.hash)
      if (result instanceof Order) {
        Order.Instance = result
      } else if (result === null) {
        Order.Instance = new Order()
      }
      // update live options
      const restaurant = Restaurant.current()
      if (restaurant) {
        restaurant.liveOptions = await Server.Instance.liveOptions()
        // update hash
        const hash = JSON.stringify(restaurant.liveOptions)
        if (hash !== this.lastLiveOptionsHash) {
          await Observer.Instance.publish(ObserverEvents.LiveOptionsChanged)
          this.lastLiveOptionsHash = hash
        }
      }
    }
  }

  private updateOrder(): void {
    this.id = setInterval(() => this.update(), 7500)
  }
}