import { Dish } from '@/models/Entities/Dish'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { Entity } from '@/models/Entities/Entity'
import { Price } from '@/models/Entities/Price'
import { Restaurant } from '@/models/Entities/Restaurant'
import { Type } from 'class-transformer'

export abstract class OrderSelectionDish {
  @Type(() => String) public id!: string
  @Type(() => String) public groupId: string
  @Type(() => String) public dishId: string
  @Type(() => String) public notes: string | null
  @Type(() => Boolean) public served = false
  @Type(() => String) public title!: string
  @Type(() => Price) public price: Price | null = null

  private _group!: DishesGroup
  private _dish!: Dish

  constructor(groupId: string, dishId: string, notes: string | null) {
    this.groupId = groupId
    this.dishId = dishId
    this.notes = notes
  }

  public get group(): DishesGroup {
    if (!this._group) {
      this._group = Restaurant.current()?.getDishesGroup(this.groupId) as DishesGroup
    }
    return this._group
  }

  public get dish(): Dish {
    if (!this._dish) {
      this._dish = Restaurant.current()?.getDish(this.dishId) as Dish
    }
    return this._dish
  }

  public isOptional(): boolean {
    return this.price !== null && this.price.value > 0
  }
}

export abstract class OrderSelection extends Entity {
  @Type(() => Boolean) public served = false
  @Type(() => Boolean) public confirmed = false
  @Type(() => Boolean) public payment = ''
  @Type(() => Number) public quantity = 0
  @Type(() => String) public title = ''
  @Type(() => Price) public unitPrice = new Price()

  public totalPrice(): Price {
    const price = new Price(this.unitPrice)
    price.value *= this.quantity
    return price
  }

  public get paid(): boolean {
    return this.payment !== undefined && this.payment !== null && this.payment !== ''
  }
}