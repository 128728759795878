import { DishesGroup } from '@/models/Entities/DishesGroup'
import { FixedMenu } from '@/models/Entities/FixedMenu'
import { Price } from '@/models/Entities/Price'
import { Restaurant } from '@/models/Entities/Restaurant'
import { Type } from 'class-transformer'
import { OrderSelection, OrderSelectionDish } from '@/models/Entities/OrderSelection'

export const OrderFixedMenuSelectionType = 'fixed-menu-line'

export class OrderFixedMenuDishSelection extends OrderSelectionDish {
}

export class OrderFixedMenuSelection extends OrderSelection {
  @Type(() => String) public type = OrderFixedMenuSelectionType
  @Type(() => String) public fixedMenuId: string
  @Type(() => OrderFixedMenuDishSelection) public selection: OrderFixedMenuDishSelection[]

  private _fixedMenu!: FixedMenu

  constructor(fixedMenuId: string, selection: OrderFixedMenuDishSelection[], quantity: number) {
    super()
    this.fixedMenuId = fixedMenuId
    this.selection = selection ?? []
    this.quantity = quantity
    // auto-assign the title and unit-price
    if (fixedMenuId) {
      this.title = this.fixedMenu.title
      this.unitPrice = this.fixedMenu.price
    }
  }

  public get fixedMenu(): FixedMenu {
    if (!this._fixedMenu) {
      this._fixedMenu = Restaurant.current()?.getFixedMenu(this.fixedMenuId) as FixedMenu
    }
    return this._fixedMenu
  }

  public totalPricePreview(): Price {
    const price = new Price(this.fixedMenu.price)
    for (let index = 0; index < this.selection.length; index++) {
      const groupPrice = this.selection[index].group.price
      if (groupPrice && groupPrice.value > 0) price.value += groupPrice.value
    }
    price.value *= this.quantity
    return price
  }

  public selectionByGroup(group: DishesGroup): OrderFixedMenuDishSelection | null {
    return this.selection.find(e => e.group.id === group.id) ?? null
  }

  public canBeOrdered(validate: boolean): boolean {
    if (!validate) return true
    return this.isFullFilled(true)
  }

  public isFullFilled(excludeOptionals: boolean): boolean {
    for (let index = 0; index < this.fixedMenu.dishesGroups.length; index++) {
      if (this.fixedMenu.dishesGroups[index].isOptional() && excludeOptionals) continue
      if (!this.selectionByGroup(this.fixedMenu.dishesGroups[index])) return false
    }
    return true
  }
}