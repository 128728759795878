import ObserverBase from 'fast-observer'

export enum ObserverEvents {
  ServerConnectionError = 'server-connection-error',
  FullscreenEnabled = 'fullscreen-enabled',
  FullscreenDisabled = 'fullscreen-disabled',
  RestaurantDidLoad = 'restaurant-did-load',
  OrderStarted = 'order-started',
  OrderChanged = 'order-changed',
  OrderLinesChanged = 'order-lines-changed',
  HideBottomBar = 'hide-bottom-bar',
  ShowBottomBar = 'show-bottom-bar',
  AppUpdateFinished = 'app-update-finished',
  AppUpdateFound = 'app-update-found',
  LiveOptionsChanged = 'live-options-changed',
}

export class Observer extends ObserverBase {
  private static _instance: Observer

  public static get Instance(): Observer {
    return this._instance || (this._instance = new this())
  }
}