export class DeliveryInfo {
    public takeaway!: boolean
    public phone!: string
    public address!: string
    public zone!: string
    public notes!: string

    public isDelivery(): boolean {
        return this.takeaway === false
    }

    public isTakeaway(): boolean {
        return this.takeaway === true
    }
}
