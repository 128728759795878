import { Entity } from '@/models/Entities/Entity'
import { Type } from 'class-transformer'
import { Server } from '@/models/Server'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { Photo } from '@/models/Entities/Photo'

export enum MenuStyle {
    PhotoGrid = 1,
    TextList = 2,
}

export class Menu extends Entity {
    @Type(() => Photo) public photo!: Photo
    @Type(() => DishesGroup) public dishesGroups!: DishesGroup[]
    public active = false
    public title!: string
    public description!: string
    public remoteDisabled = false
    public style = MenuStyle.PhotoGrid

    public photoUrl(): string {
        if (this.photo) {
            return Server.Instance.resourceUrl('photo/menu/' + this.id + '?nc=' + this.photo.id);
        }
        return require('@/assets/images/common/dish-no-photo.svg');
    }

    public getDishesGroup(id: string): DishesGroup | null {
        return this.dishesGroups.find(e => e.id === id) ?? null
    }
}