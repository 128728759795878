import { DishesGroup } from '@/models/Entities/DishesGroup'
import { OrderFixedMenuDishSelection } from '@/models/Entities/OrderFixedMenuSelection'
import { Price } from '@/models/Entities/Price'
import { Type } from 'class-transformer'
import { Dish } from './Dish'
import { OrderSelection, OrderSelectionDish } from './OrderSelection'
import { Restaurant } from './Restaurant'

export const OrderDishSelectionType = 'dish-line'

export class OrderDishComplementsSelection extends OrderSelectionDish { }

export class OrderDishSelection extends OrderSelection {
  @Type(() => String) public type = OrderDishSelectionType
  @Type(() => String) public dishId: string
  @Type(() => String) public notes: string | null
  @Type(() => OrderDishComplementsSelection) public selection: OrderDishComplementsSelection[]

  private _dish!: Dish

  constructor(dishId: string, quantity: number, notes: string | null, selection: OrderDishComplementsSelection[]) {
    super()
    this.dishId = dishId
    this.quantity = quantity
    this.notes = notes
    this.selection = selection
  }

  public get dish(): Dish {
    if (!this._dish) {
      this._dish = Restaurant.current()?.getDish(this.dishId) as Dish
    }
    return this._dish
  }

  public totalPricePreview(): Price {
    const price = new Price(this.dish.price)
    for (let index = 0; index < this.selection.length; index++) {
      const groupPrice = this.selection[index].group.price
      if (groupPrice && groupPrice.value > 0) price.value += groupPrice.value
    }
    price.value *= this.quantity
    return price
  }

  public selectionByGroup(group: DishesGroup): OrderFixedMenuDishSelection | null {
    return this.selection.find(e => e.group.id === group.id) ?? null
  }

  public canBeOrdered(validate: boolean): boolean {
    if ( ! validate) return true
    return this.isFullFilled(true)
  }

  public isFullFilled(excludeOptionals: boolean): boolean {
    if (this.dish?.hasComplements()) {
      for (let index = 0; index < this.dish.dishesGroups.length; index++) {
        if (this.dish.dishesGroups[index].isOptional() && excludeOptionals) continue
        if (!this.selectionByGroup(this.dish.dishesGroups[index])) return false
      }
    }
    return true
  }
}