import { Entity } from "./Entity"
import { Restaurant } from "./Restaurant"
import { ONLINE_TABLE_ID, Tables } from "./Tables"

export class Table extends Entity {
    public tablesId!: string
    public tableNum!: number
    public people!: number

    public get tables(): Tables {
        return Restaurant.current()?.getTables(this.tablesId) as Tables
    }

    public isComplete(): boolean {
        if (this.tablesId === ONLINE_TABLE_ID) {
            return true
        } else if (this.tablesId !== undefined && this.tableNum !== undefined && this.people !== undefined) {
            return this.selectedTableExists() && this.people > 0
        }
        return false
    }

    private selectedTableExists(): boolean {
        if (this.tables && this.tableNum !== undefined) {
            return this.tableNum > 0 && this.tableNum <= this.tables.count
        }
        return true
    }

    public description(): string {
        return this.tables ? this.tables.getTitle() + ' - Mesa ' + this.tableNum : ''
    }
}