import { Dish } from '@/models/Entities/Dish'
import { DishesGroup } from '@/models/Entities/DishesGroup'
import { FixedMenu } from '@/models/Entities/FixedMenu'
import { LiveOptions } from '@/models/Entities/LiveOptions'
import { Menu } from '@/models/Entities/Menu'
import { Order } from '@/models/Entities/Order'
import { Photo } from '@/models/Entities/Photo'
import { SalesChannels } from '@/models/Entities/SalesChannels'
import { Tables } from '@/models/Entities/Tables'
import { Observer, ObserverEvents } from '@/models/Observer'
import { Server } from '@/models/Server'
import { Type } from 'class-transformer'
import tinycolor from 'tinycolor2'

export class Restaurant {
  @Type(() => String) public id!: string
  @Type(() => String) public alias!: string

  @Type(() => String) public name!: string
  @Type(() => Photo) public logo!: Photo
  @Type(() => String) public color!: string
  @Type(() => Tables) public tables!: Tables[]

  @Type(() => Boolean) public cashPayments!: boolean
  @Type(() => Boolean) public cardPayments!: boolean
  @Type(() => Boolean) public onlinePayments!: boolean

  @Type(() => SalesChannels) public salesChannels!: SalesChannels

  @Type(() => Menu) public menus!: Menu[]
  @Type(() => FixedMenu) public fixedMenus!: FixedMenu[]

  @Type(() => Order) public activeOrder?: Order

  @Type(() => LiveOptions) public liveOptions: LiveOptions | null = null

  private _cachedDishes: Record<string, Dish> = {}
  private _cachedDishesGroup: Record<string, DishesGroup> = {}

  private static _instance: Restaurant | null

  public static current(): Restaurant | null {
    return this._instance
  }

  public static async get(id: string): Promise<Restaurant | null> {
    if (this._instance && (this._instance.id === id || this._instance.alias === id)) {
      return this._instance
    }
    // get information from server
    this._instance = await Server.Instance.restaurant(id)
    // is an order active?
    if (this._instance?.activeOrder) {
      Order.Instance = this._instance.activeOrder
      delete this._instance.activeOrder
    }
    // publish change
    await Observer.Instance.publish(ObserverEvents.RestaurantDidLoad)
    // the instance
    return this._instance
  }

  public getTables(id: string): Tables | null {
    return this.tables?.find(e => e.id === id) ?? null
  }

  public getMenu(id: string): Menu | null {
    return this.menus.find(e => e.id === id) ?? null
  }

  public getFixedMenu(id: string): FixedMenu | null {
    return this.fixedMenus.find(e => e.id === id) ?? null
  }

  private findDishesGroup(id: string, dishesGroups: DishesGroup[]): DishesGroup | null {
    // look in initial groups
    let group = dishesGroups.find(e => e.id === id)
    if (group) {
      return group
    }
    // look into dishes (from initial groups)
    for (let index = 0; index < dishesGroups.length; index++) {
      group = dishesGroups[index]
      for (let dishIndex = 0; dishIndex < group.dishes.length; dishIndex++) {
        const dish = group.dishes[dishIndex]
        if (dish.hasComplements()) {
          const result = this.findDishesGroup(id, dish.dishesGroups)
          if (result) {
            return result
          }
        }
      }
    }
    return null
  }

  public getDishesGroup(id: string): DishesGroup | null {
    if (this._cachedDishesGroup[id]) {
      return this._cachedDishesGroup[id]
    }
    // look into menus
    for (let index = 0; index < this.menus.length; index++) {
      const group = this.findDishesGroup(id, this.menus[index].dishesGroups)
      if (group) {
        this._cachedDishesGroup[id] = group
        return group
      }
    }
    // look into fixed-menus
    for (let index = 0; index < this.fixedMenus.length; index++) {
      const group = this.findDishesGroup(id, this.fixedMenus[index].dishesGroups)
      if (group) {
        this._cachedDishesGroup[id] = group
        return group
      }
    }
    return null
  }

  public getDish(id: string): Dish | null {
    if (this._cachedDishes[id]) {
      return this._cachedDishes[id]
    }
    for (let index = 0; index < this.menus.length; index++) {
      const dish = this.findDishInDishesGroups(id, this.menus[index].dishesGroups)
      if (dish) {
        this._cachedDishes[id] = dish
        return dish
      }
    }
    for (let index = 0; index < this.fixedMenus.length; index++) {
      const dish = this.findDishInDishesGroups(id, this.fixedMenus[index].dishesGroups)
      if (dish) {
        this._cachedDishes[id] = dish
        return dish
      }
    }
    return null
  }

  private findDishInDishesGroups(id: string, groups: DishesGroup[]): Dish | null {
    if (groups) {
      for (let index = 0; index < groups.length; index++) {
        for (let dishIndex = 0; dishIndex < groups[index].dishes.length; dishIndex++) {
          const dish = groups[index].dishes[dishIndex]
          if (dish.id === id) return dish
          if (dish.hasComplements()) {
            const result = this.findDishInDishesGroups(id, dish.dishesGroups)
            if (result) return result
          }
        }
      }
    }
    return null
  }

  public logoUrl(): string {
    if (this.logo) {
      return Server.Instance.resourceUrl('photo/profile/' + this.id + '?nc=' + this.logo.id)
    }
    return 'https://api.dicebear.com/7.x/initials/svg?seed=' + encodeURI(this.name)
  }

  public availablePaymentMethods(): number {
    let count = 0
    count += this.cardPayments ? 1 : 0
    count += this.cashPayments ? 1 : 0
    count += this.onlinePayments ? 1 : 0
    return count
  }

  public visibleTables(): Tables[] {
    if (this.tables) return this.tables.filter(table => !table.remoteDisabled)
    return []
  }

  public visibleMenus(): Menu[] {
    if (this.menus) return this.menus.filter(menu => !menu.remoteDisabled)
    return []
  }

  public visibleFixedMenus(): FixedMenu[] {
    if (this.fixedMenus) return this.fixedMenus.filter(fixedMenu => !fixedMenu.remoteDisabled)
    return []
  }

  public hasActiveChannels(): boolean {
    if (this.salesChannels) {
      return this.salesChannels.hasActiveChannels()
    }
    return false
  }

  public isOnlineOnly(): boolean {
    return this.salesChannels.onlineActive() && this.visibleTables().length === 0
  }

  public colors(): Record<string, string> {

    const color = tinycolor(this.color)
    const luminance = color.getLuminance()

    let colorDark = undefined
    let colorLight = undefined

    if (luminance > 0.95) {
      colorDark = tinycolor(this.color).darken(40)
      colorLight = tinycolor(this.color).darken(90)
    } else if (luminance > 0.75) {
      colorDark = tinycolor(this.color).darken(40)
      colorLight = tinycolor(this.color).darken(60)
    } else if (luminance > 0.65) {
      colorDark = tinycolor(this.color).darken(20)
      colorLight = tinycolor(this.color).darken(20)
    } else if (luminance > 0.50) {
      colorDark = tinycolor(this.color).darken(40)
      colorLight = tinycolor(this.color).brighten(60)
    } else if (luminance > 0.35) {
      colorDark = tinycolor(this.color).darken(30)
      colorLight = tinycolor(this.color).brighten(50)
    } else {
      colorDark = tinycolor(this.color).brighten(60)
      colorLight = tinycolor(this.color).brighten(100)
    }

    return {
      '--color': color.toString(),
      '--color-dark': colorDark.toString(),
      '--color-light': colorLight.toString(),
      '--color-alpha-50': tinycolor(this.color).setAlpha(0.5).toRgbString(),
    }
  }
}
