<template>
  <div v-if="isConnectionError">
    <error-dialog title="Error de conexión" icon="fal fa-wifi-slash">
      <template v-slot:description>
        <p>
          No se ha podido establecer una conexión con el servidor, por favor
          comprueba tu conexión a Internet y vuelve a probarlo.
        </p>
      </template>
      <template v-slot:buttons>
        <button type="button" class="button is-black" @click="reconnect()">
          Volver a probar
        </button>
      </template>
    </error-dialog>
  </div>
  <div v-else :class="{ 'bottom-bar-visible': isBottomBarVisible }" :style="colors">
    <router-view />
    <bottom-bar />
  </div>
  <modal-confirmation :style="colors" />
  <div v-if="updating" class="update-view">
    <div class="is-loading"></div>
  </div>
</template>

<script lang="ts">

import { Options, Vue } from "vue-class-component"
import { Observer, ObserverEvents } from "@/models/Observer"
import { Restaurant } from "./models/Entities/Restaurant"
import { Crontab } from "./models/Crontab"
import ErrorDialog from "@/components/ErrorDialog.vue"
import BottomBar from "@/components/BottomBar.vue"
import ModalConfirmation from '@/components/ModalConfirmation.vue'

@Options({
  components: {
    ErrorDialog,
    BottomBar,
    ModalConfirmation,
  },
})
export default class App extends Vue {

  public isConnectionError = false
  public isBottomBarVisible = true

  private updating = false

  private colors: Record<string, string> | undefined = {}

  public beforeCreate(): void {

    // update theme
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#ffffff');

    Observer.Instance.subscribe(ObserverEvents.AppUpdateFound, () => {
      this.updating = true
    })

    Observer.Instance.subscribe(ObserverEvents.AppUpdateFinished, () => {
      this.updating = false
    })

    Observer.Instance.subscribe(ObserverEvents.ServerConnectionError, () => {
      this.isConnectionError = true
    })

    Observer.Instance.subscribe(ObserverEvents.ShowBottomBar, () => {
      this.isBottomBarVisible = true
    })

    Observer.Instance.subscribe(ObserverEvents.HideBottomBar, () => {
      this.isBottomBarVisible = false
    })

    Observer.Instance.subscribe(ObserverEvents.RestaurantDidLoad, () => {
      // update theme
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#' + Restaurant.current()?.color);
      // assign colors
      this.colors = Restaurant.current()?.colors()
    })

    Crontab.Instance.start()
  }

  private reconnect(): void {
    this.$router.go(0)
  }
}
</script>

<style lang="scss">
@import "assets/styles/style.scss";

.update-view {
  background: rgba($color: #fff, $alpha: 0.95);
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>