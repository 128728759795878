import { PUBLIC_BASE_URL } from '@/models/Consts'
import { LiveOptions } from '@/models/Entities/LiveOptions'

import { ServerResponse } from '@/models/ServerResponse'
import axios from 'axios'
import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer'
import { DeliveryInfo } from './Entities/DeliveryInfo'
import { Order } from './Entities/Order'
import { OrderDishSelection } from './Entities/OrderDishSelection'
import { OrderFixedMenuSelection } from './Entities/OrderFixedMenuSelection'
import { PayCall } from './Entities/PayCall'
import { Price } from './Entities/Price'
import { Restaurant } from './Entities/Restaurant'
import { Observer, ObserverEvents } from './Observer'
import { ServerRequest } from './ServerRequest'
import { randomId } from './Tools'

export enum ServerErrorCodes {
  OrderRequestRequireCode = 'ERR_ORDER_REQUEST_CODE_IS_REQUIRED',
  OrderRequestInvalidCode = 'ERR_ORDER_REQUEST_CODE_IS_INVALID',
}

const API_URL_PATH = 'api/v1/diners/'

export class Server {
  private static _instance: Server

  public static get Instance(): Server {
    return this._instance || (this._instance = new this())
  }

  constructor() {
    // on prepare request
    axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        return config
      },
      error => {
        return Promise.reject(error)
      },
    )
    // on parse response
    axios.interceptors.response.use(
      response => {
        if (response.headers['response-type'] === 'api-response') {
          response.data = plainToInstance(ServerResponse, response.data)
        }
        return response
      },
      error => {
        if (!error.response) {
          Observer.Instance.publish(ObserverEvents.ServerConnectionError)
        }
        return Promise.reject(error)
      },
    )
  }

  // HTTP methods

  private GET(method: string): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      const nr = randomId(5)
      axios.get(`${ PUBLIC_BASE_URL }${ API_URL_PATH }${ method }?nr=${ nr }`).then(response => {
        resolve(response.data)
      })
    })
  }

  private POST(method: string, request: ServerRequest): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      axios.post(`${ PUBLIC_BASE_URL }${ API_URL_PATH }${ method }`, instanceToPlain(request, { excludePrefixes: ['_'] })).then(response => {
        resolve(response.data)
      })
    })
  }

  private PUT(method: string, request: ServerRequest): Promise<ServerResponse> {
    return new Promise<ServerResponse>(resolve => {
      axios.put(`${ PUBLIC_BASE_URL }${ API_URL_PATH }${ method }`, instanceToPlain(request, { excludePrefixes: ['_'] })).then(response => {
        resolve(response.data)
      })
    })
  }

  // API Standard calls

  private get<T>(method: string, type: ClassConstructor<T>, id: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.GET(`${ method }/${ id }`).then((response: ServerResponse) => {
        if (response.success)
          resolve(plainToInstance(type, response.data))
        else // error
          reject(response)
      })
    })
  }

  private async post<T>(method: string, type: ClassConstructor<T>, data: unknown): Promise<T> {
    let result: T
    let error: ServerResponse
    try {
      const response = await this.POST(method, new ServerRequest(data))
      if (response.success) {
        result = plainToInstance(type, response.data)
      } else {
        error = response
      }
    } catch (e) {
      error = e as ServerResponse
    }
    return new Promise<T>((resolve, reject) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  }

  private async put<T>(method: string, type: ClassConstructor<T>, data: unknown): Promise<T> {
    let result: T
    let error: ServerResponse
    try {
      const response = await this.PUT(method, new ServerRequest(data))
      if (response.success) {
        result = plainToInstance(type, response.data)
      } else {
        error = response
      }
    } catch (e) {
      error = e as ServerResponse
    }
    return new Promise<T>((resolve, reject) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  }

  // Helpers

  public resourceUrl(method: string): string {
    return `${ PUBLIC_BASE_URL }${ API_URL_PATH }resource/${ method }`
  }

  // Public methods

  public restaurant(id: string): Promise<Restaurant | null> {
    return this.get('restaurant', Restaurant, id)
  }

  public liveOptions(): Promise<LiveOptions | null> {
    return this.get('live-options', LiveOptions, '')
  }

  public async initOrder(restaurant: Restaurant, order: Order, code: string | null): Promise<Order | null> {
    const data = {
      ownerId: restaurant.id,
      tablesId: order.table.tablesId,
      tableNum: order.table.tableNum,
      people: order.table.people,
      code: code,
    }
    const result = await this.post('order/init', Order, data)
    // order started
    Observer.Instance.publish(ObserverEvents.OrderStarted).then()
    // the order
    return result
  }

  public async activeOrder(hash: string | null = null): Promise<null | false | Order> {
    const response = await this.POST('order', new ServerRequest(hash ? { hash: hash } : {}))
    if (response.success) {
      if (response.data === false) {
        return false
      } else if (response.data !== undefined) {
        return plainToInstance(Order, response.data)
      }
    }
    return null
  }

  public cancelOrder(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.POST('order/cancel', new ServerRequest({})).then((response: ServerResponse) => {
        if (response.success)
          resolve(response.data as boolean)
        else // error
          reject(response)
      })
    })
  }

  public addOrderLine(line: OrderDishSelection | OrderFixedMenuSelection): Promise<OrderDishSelection | OrderFixedMenuSelection> {
    if (line instanceof OrderDishSelection) {
      return this.post('order/lines/add', OrderDishSelection, line)
    } else {
      return this.post('order/lines/add', OrderFixedMenuSelection, line)
    }
  }

  public setOrderLineQuantity(line: OrderDishSelection | OrderFixedMenuSelection, quantity: number): Promise<OrderDishSelection | OrderFixedMenuSelection> {
    if (line instanceof OrderDishSelection) {
      return this.put(`order/lines/${ line.id }/quantity`, OrderDishSelection, quantity)
    } else {
      return this.put(`order/lines/${ line.id }/quantity`, OrderFixedMenuSelection, quantity)
    }
  }

  public updateOrderLine(line: OrderDishSelection | OrderFixedMenuSelection): Promise<OrderDishSelection | OrderFixedMenuSelection> {
    if (line instanceof OrderDishSelection) {
      return this.put(`order/lines/${ line.id }/update`, OrderDishSelection, line)
    } else {
      return this.put(`order/lines/${ line.id }/update`, OrderFixedMenuSelection, line)
    }
  }

  public confirmOrder(): Promise<Order> {
    return new Promise<Order>((resolve, reject) => {
      this.PUT('order/confirm', new ServerRequest({})).then((response: ServerResponse) => {
        if (response.success)
          resolve(plainToInstance(Order, response.data))
        else // error
          reject(response)
      })
    })
  }

  public payOrder(tip: Price): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data = { tip: tip }
      this.PUT('order/pay/init', new ServerRequest(data)).then((response: ServerResponse) => {
        if (response.success)
          resolve(response.data as string)
        else // error
          reject(response)
      })
    })
  }

  public payOnlineOrder(delivery: DeliveryInfo, tip: Price): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data = { delivery: delivery, tip: tip }
      this.PUT('order/online/pay/init', new ServerRequest(data)).then((response: ServerResponse) => {
        if (response.success)
          resolve(response.data as string)
        else // error
          reject(response)
      })
    })
  }

  public waiterPayCall(cash: boolean): Promise<PayCall> {
    return new Promise<PayCall>((resolve, reject) => {
      this.PUT('waiter/call/payment', new ServerRequest(cash)).then((response: ServerResponse) => {
        if (response.success)
          resolve(plainToInstance(PayCall, response.data))
        else // error
          reject(response)
      })
    })
  }
}
