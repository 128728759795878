import { Type } from "class-transformer"
import { Price } from "./Price"

export class SalesChannels {
    @Type(() => Boolean) public inSitu = true
    @Type(() => Boolean) public takeaway = false
    @Type(() => Boolean) public delivery = false
    @Type(() => Price) public minimumOrderPrice = new Price()
    @Type(() => Price) public shippingCostPrice = new Price()
    @Type(() => String) public shippingZones: string[] = []

    public hasActiveChannels(): boolean {
        return this.inSitu || this.takeaway || this.delivery
    }

    public noActiveChannels(): boolean {
        return !this.inSitu && !this.takeaway && !this.delivery
    }

    public onlineActive(): boolean {
        return this.takeaway || this.delivery
    }

    public allOnlineActive(): boolean {
        return this.takeaway && this.delivery
    }

    public inSituAndSomeOnlineActive(): boolean {
        return this.inSitu && (this.takeaway || this.delivery)
    }

    public inSituOnly(): boolean {
        return this.inSitu && !(this.takeaway && this.delivery)
    }

    public takeawayOrDeliveryOnly(): boolean {
        return !this.inSitu && (this.takeaway || this.delivery)
    }
}