export class Price {
  public value!: number
  public code!: string

  public constructor(other: Price | null = null) {
    this.value = other ? other.value : 0
    this.code = other ? other.code : 'EUR'
  }

  public toString(): string {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: this.code
    })
    return formatter.format(this.value)
  }
}