import 'reflect-metadata'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

//import '@fortawesome/fontawesome-pro/js/light.js'
//import '@fortawesome/fontawesome-pro/js/fontawesome.js'
import '@fortawesome/fontawesome-pro/css/all.css'
import 'animate.css/animate.css'

createApp(App).use(router).mount('#app')