import { Entity } from "./Entity"

export const ONLINE_TABLE_ID = 'online'

export class Tables extends Entity {
    public active!: boolean
    public title!: string
    public description!: string
    public count!: number
    public extraFee!: number
    public remoteDisabled = false

    public getTitle(): string {
        let text = this.title
        if (this.hasExtraFee()) text += ' (' + this.extraFeeStr() + ')'
        return text
    }

    public extraFeeStr(): string {
        if (this.extraFee > 0) return '+' + this.extraFee + '%'
        else if (this.extraFee < 0) return this.extraFee + '%'
        return ''
    }

    public hasExtraFee(): boolean {
        return this.extraFee < 0 || this.extraFee > 0
    }
}